.footer {
  background: #0d1626;
  padding: 50px 100px;
  color: white;
  display: grid;
  grid-template-columns: 1.3fr 1fr 1fr 1fr;
  gap: 80px;
}
.footer div img {
  height: 15px;
  object-fit: contain;
  margin-bottom: 30px;
}
.footer div h1 {
  font-family: "Sorts Mill Goudy", serif;
  font-size: 35px;
  color: white;
}
.footer div p {
  padding: 10px 0;
}
.social {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.social a {
  border-radius: 100px;
  width: 25px;
  height: 25px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  &:hover {
    border: 1px solid white;
  }
}

.links li {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  position: relative;
  padding: 10px 0;
  &:hover {
    &::before {
      opacity: 1;
    }
  }
  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: currentColor;
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    opacity: 0;
    transition: 0.3s ease-out;
  }
}

@media (max-width: 1200px) {
  .footer {
    grid-template-columns: repeat(2, 1fr);
    text-align: left !important;
    row-gap: 80px;
  }
}
@media (max-width: 880px) {
  .footer {
    padding-top: 110px;
    padding-bottom: 110px;
    /* width: 86%; */
    margin: auto;
    grid-template-columns: 1fr;
    text-align: left !important;
    row-gap: 80px;
    padding-inline: 0;
  }
  .wrapper {
    background: #0d1626;
  }
}
