.testimonial {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 700px;
}
.testimonialLeft {
  height: 100%;
}
.testimonialLeft img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonialRight {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url("../../assets/main-testimonials-bck.jpg");
  background-size: cover;
  position: relative;
}

.testimonialRight .content {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  padding: 0 50px;
  text-align: center;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  display: grid;
  grid-template-rows: 20px auto 50px;
  grid-gap: 30px;
  gap: 30px;
}

.content h1 {
  font-size: 30px;
  font-family: "Sorts Mill Goudy", serif;
}

.content img {
  width: auto;
  height: 100%;
  margin: auto;
}

.content h3 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  color: #99794c;
}
@media (max-width: 1500px) {
  .testimonial {
    grid-template-rows: 600px;
  }
}
@media (max-width: 1200px) {
  .testimonial {
    display: flex;
    /* height: 1270px; */
    flex-direction: column;
  }
  .testimonialRight {
    height: 550px;
  }
}
@media (max-width: 880px) {
  .content h1 {
    font-size: 27px;
    line-height: 1.36667em;
  }
}
@media (max-width: 660px) {
  .testimonialRight {
    height: 650px;
  }
}
@media (max-width: 450px) {
  .testimonialRight {
    height: 750px;
  }
}
