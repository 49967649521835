.topics {
  display: flex;
  justify-content: space-between;
  gap: 120px;
  align-items: center;
  padding: 100px 0;
}

.topics div:nth-child(1) h4 {
  text-align: end;
}

.topics img {
  height: auto;
}

.topics div h4 {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
}

@media (max-width: 1250px) {
  .topics {
    gap: 50px;
  }
}
@media (max-width: 880px) {
  .topics {
    display: flex;
    justify-content: center;

    gap: 50px;
    flex-wrap: wrap;
    padding: 0px 0;
  }
  .individualCard {
    width: 40%;
  }
}
@media (max-width: 768px) {
  .topics {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
    flex-wrap: wrap;

    align-items: center;
    padding: 0 0;
  }
  .individualCard {
    width: 100%;
  }
  .individualCard-0 {
    padding-inline: 7%;
    text-align: right;
  }
  .individualCard-1 {
    padding-inline: 30px;
    text-align: left;
  }
  .individualCard-1 img {
    width: max-content;
  }
  .individualCard-2 {
    padding-inline: 7%;

    text-align: left;
  }
  .individualCard-2 img {
    width: 100%;
  }
  .individualCard-3 {
    padding-inline: 30px;
    text-align: left;
  }
}
