.appointment {
  background-image: url("../../assets/main-home-bck-2.png");
  width: 100%;
  height: 400px;
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding-top: 100px;
}

.appointment img {
  height: 15px;
  object-fit: contain;
  margin-bottom: 10px;
}

.appointment h1 {
  font-family: "Sorts Mill Goudy", serif;
  font-size: 45px;
}

.appointment button {
  margin-top: 20px;
  padding: 15px 25px;
}
@media (max-width: 1200px) {
  .appointment h1 {
    font-size: 40px;
    line-height: 1.22449em;
  }
  .appointment p {
    margin-top: 15px;
  }

  .appointment button {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .appointment {
    height: auto;
  }
}
