.experience {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 100px;
  gap: 100px;
  margin-bottom: 150px;
  margin-top: 50px;
}

.experienceRight {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  gap: 20px;
}
.experienceRight a img:nth-child(2) {
  opacity: 1;
  visibility: hidden;
  display: none;
}

.experienceRight a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 20px 50px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}
.experienceRight a:hover img:nth-child(1) {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

@media (max-width: 1480px) {
  .experienceRight {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
