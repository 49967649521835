.blogPosts {
  margin: 100px 0;
}
.item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
  padding: 30px;
}

.top {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 0;
}

.date {
  background-color: #0b2c4d;
  color: white;
  padding: 0 20px;
  font-size: 12px;
}

.tags span {
  font-size: 12px;
  padding-left: 10px;
  color: #99794c;
  font-weight: 500;
}

.item h1 {
  font-family: "Sorts Mill Goudy", serif;
  font-size: 30px;
}

.info {
  display: grid;
  align-items: center;
}
.info p {
  font-size: 16px;
}

.item button {
  padding: 15px 30px;
  float: right;
}


