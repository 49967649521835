.parallax {
  /* The image used */
  background-image: url("../../assets/parallax-img.jpg");
  width: 100%;
  /* Set a specific height */
  min-height: 500px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .parallax {
    min-height: 400px;
    background-attachment: scroll; /* Change from fixed to scroll */
  }
}
