.heroSection {
  width: 100%;
  height: 100vh;
}
.slide {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.sliderImage {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  animation: slideAnimatew 6s ease-in-out;
  -webkit-animation: slideAnimatew 6s ease-in-out;
}

.sliderContent {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.sliderContent h1 {
  font-size: 110px;
  text-align: center;
  color: white;
  font-weight: 500;
  animation: fadeUp 0.7s ease-in-out;
  -webkit-animation: fadeUp 0.7s ease-in-out;
}

.sliderContent h2 {
  font-size: 30px;
  text-align: center;
  color: white;
  font-weight: 500;
  animation: fadeUp 0.7s ease-in-out;
  -webkit-animation: fadeUp 0.7s ease-in-out;
}

.sliderContent img {
  width: 380px;
  height: auto;
  display: flex;
  margin: auto;
  position: relative;
  margin-top: -70px;
  animation: fadeUpImg 1.5s ease-in-out;
  -webkit-animation: fadeUpImg 1.5s ease-in-out;
}

@keyframes fadeUpImg {
  0% {
    opacity: 0;
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(70px);
    -webkit-transform: translateY(70px);
    -moz-transform: translateY(70px);
    -ms-transform: translateY(70px);
    -o-transform: translateY(70px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@keyframes slideAnimatew {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  100% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
  }
}

@media (max-width: 1250px) {
  .sliderContent h1 {
    font-size: 80px;
  }
  .sliderContent img {
    width: 300px;
    margin-top: -50px;
  }
}
@media (max-width: 690px) {
  .sliderContent h1 {
    line-height: 60px;
    letter-spacing: -3px;
    font-weight: 400;
    font-size: 60px;
  }
}
