.details {
  overflow-x: hidden;
  padding: 100px 0;
  padding-left: 150px;
  display: grid;
  grid-template-columns: 2fr 650px;
  grid-gap: 50px;
  gap: 50px;
  align-items: center;
}

.rightDetails {
  display: flex;
  align-items: center;
  gap: 50px;
}

.leftDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
}

.itemTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}
.itemTitle h2 {
  font-size: 19px;
}
.itemTitle img {
  width: 15px;
  height: 15px;
}
.leftDetails > div {
  padding-right: 50px;
}

.leftDetails p {
  font-size: 16px;
  margin-bottom: 20px;
}

.leftDetails a {
  font-size: 12px;
  border-bottom: 1px solid gray;
  font-weight: 600;
  letter-spacing: 2px;
}

@media (max-width: 1500px) {
  .details {
    padding-left: 50px;
    grid-gap: 25px;
    gap: 25px;
    justify-content: space-between;

    grid-template-columns: 60% 40%;
  }
  .rightDetails {
    width: 40%;
  }
}

@media (max-width: 1200px) {
  .details {
    display: flex;
    flex-direction: column;
  }
  .leftDetails {
    width: 86%;

    margin-left: 0;
  }
  .rightDetails {
    margin-top: 40px;
    width: 86%;
    justify-content: start;
    gap: 230px;
    text-align: center;
  }
}
@media (max-width: 1100px) {
  .rightDetails {
    gap: 200px;
  }
}
@media (max-width: 1030px) {
  .rightDetails {
    gap: 150px;
    text-align: center;
  }
}
@media (max-width: 930px) {
  .rightDetails {
    gap: 100px;
    text-align: center;
  }
}
@media (max-width: 830px) {
  .rightDetails {
    gap: 0px;
    /* justify-content: space-between; */
    text-align: center;
  }
}
@media (max-width: 768px) {
  .leftDetails {
    padding-inline-end: 15%;
    flex-direction: column;
    width: 100%;
    display: flex;
  }
  .details {
    padding-left: calc((100% - 86%) / 2);
  }
  .rightDetails {
    display: flex;
    width: 100%;
    row-gap: 70px;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
}
