@import url("https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy:ital@0;1&family=Work+Sans:wght@100;200;300;400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  color: #333; /* Default text color */
  word-wrap: break-word !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: #333; /* Header color */
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
}

.container {
  max-width: 1400px;
  margin: auto;
}

/* Custom CSS */
.swiper-button-prev {
  color: white !important;
}

.swiper-button-next {
  color: white !important;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 20px;
}

span.swiper-pagination-bullet {
  margin: 0px 20px !important;
  background-color: white;
}

.btn {
  background-color: transparent;
  color: #99794c;
  border: 1px solid #99794c;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  outline: none;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 12px;
  transition: all 0.4s;

  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  &:hover {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }
}


@media (max-width: 1480px) {
  .container{
    max-width: 1000px;
  }
}